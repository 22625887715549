import React from "react"
import { IoMdArrowBack } from "react-icons/io"

import Layout from "../components/layout"
import SEO from "../components/SEO"
import { Link } from "gatsby"
import PageContentContainer from "../components/PageContentContainer"
import BannerContainer from "../components/bannerContainer"
import ButtonLink from "../components/ButtonLink"
import { FaBackward } from "react-icons/fa"

const SuccessPage = () => (
  <Layout>
    <SEO title="Sent!" />
    <PageContentContainer>
      <BannerContainer color="bg-secondary">
        <div className="grid  justify-items-center gap-y-10 text-primary py-20">
          <h1>Success!</h1>
          <p className="text-2xl">Your message has been sent.</p>
          <ButtonLink
            className="px-6 py-1 text-xl md:text-2xl uppercase tracking-wide flex items-center"
            to="/contact"
          >
            <IoMdArrowBack className="mr-3" />
            <span>Contact Page</span>
          </ButtonLink>
        </div>
      </BannerContainer>
    </PageContentContainer>
  </Layout>
)

export default SuccessPage
